<template>
  <div>
    <v-card-text>
      <v-alert v-if="!nombrecanal" text type="info"
        >Es necesario seleccionar un asociado para poder navegar en el
        sistema.</v-alert
      >
      <div v-if="group == 'externo'">
        <v-card class="mx-auto" width="100%">
          <v-card-text>
            <div>{{ currentUser.asociado.codigo }}</div>
            <p class="text-h4 text--primary">{{ currentUser.nombre }}</p>
            <div class="title text--primary">Sucursales</div>
            <v-simple-table height="300">
              <thead>
                <tr>
                  <th class="text-left">Código</th>
                  <th class="text-left">Nombre</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sucursales" :key="item.name">
                  <td>{{ item.CodSucursal }}</td>
                  <td>{{ item.Nombre }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="group == 'interno'">
        <v-radio-group v-model="grupo" row v-if="gestionados.length">
          <v-radio label="Cadena de mando" value="cadena"></v-radio>
          <v-radio label="Gestionados" value="gestionado"></v-radio>
        </v-radio-group>
        <v-simple-table fixed-header height="300px" v-if="grupo == 'cadena'">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Tarjeta</th>
                <th class="text-left">Nombre</th>
                <th class="text-left">Grupo Comisión</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in empleados" :key="item.EmpleadosId">
                <td class="text-uppercase">{{ item.CodigoTarjeta }}</td>
                <td>{{ item.Nombre }}</td>
                <td>{{ item.Puesto }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <br />
        <v-simple-table
          fixed-header
          height="300px"
          v-if="grupo == 'gestionado' && gestionados.length"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Código</th>
                <th class="text-left">Sucursal</th>
                <th class="text-left">Asociado</th>
                <th class="text-left">Canal</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in gestionados" :key="item.CanalSucursalId">
                <td>{{ item.CodigoSucursal }}</td>
                <td class="text-capitalize">{{ item.NombreSucursal }}</td>
                <td>{{ item.CodigoAsociado }}</td>
                <td>{{ item.Canal }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- <hr /> -->
      <div class="text-right">
        <v-btn
          class="mr-1"
          text
          depressed
          color="info"
          v-if="group == 'interno'"
          @click="confirm"
          :disabled="empleados.length == 0"
          >Confirmar</v-btn
        >
      </div>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />

        <v-tabs>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab href="#externo" @click.native="group = 'externo'"
            >Externo</v-tab
          >
          <v-tab href="#interno" @click.native="group = 'interno'"
            >Interno</v-tab
          >
          <v-tab-item value="externo">
            <br />
            <v-row row wrap>
              <v-col cols="12" sm="12" class="d-flex">
                <seleccion-asociado v-if="isRolComisiones"></seleccion-asociado>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="interno">
            <br />
            <v-row row wrap>
              <v-col cols="12">
                <v-select
                  :items="canalOptions"
                  v-model="canalSelected"
                  placeholder="Seleccione un canal"
                  item-text="Nombre"
                  item-value="CanalId"
                  append-icon="keyboard_arrow_down"
                  label="Canal"
                  hint="Canal"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="empleadoSelected"
                  :items="empleadoOptions"
                  placeholder="Seleccione un empleado"
                  item-text="Nombre"
                  item-value="EmpleadosId"
                  append-icon="keyboard_arrow_down"
                  label="Empleado"
                  hint="Empleado"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedCorte"
                  :items="corteOptions"
                  placeholder="Seleccione un corte"
                  item-text="fechas"
                  item-value="CorteId"
                  append-icon="keyboard_arrow_down"
                  label="Corte"
                  hint="Corte"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>

            <br />
            <div class="mb-5 text-right">
              <v-btn block depressed color="primary" @click="search">
                <v-icon left>search</v-icon>Buscar
                <span slot="loader">Buscando...</span>
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import seleccionAsociado from "@/components/seleccion_asociado";
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";
import moment from 'moment'

export default {
  components: {
    seleccionAsociado,
  },
  data() {
    return {
      drawerRight: true,
      url: "",
      group: "externo",
      grupo: "cadena",
      nombrecanal: null,
      cortes: [],
      empleados: [],
      gestionados: [],
      canalSelected: null,
      selectedCorte: null,
      empleadoSelected: null,
      selectedEmpleado: [],
      empleadoOptions: [],
      corteOptions: [],
      progress: false,
      headers: [
        { text: "TARJETA", value: "CodigoTarjeta" },
        { text: "NOMBRE", value: "Nombre" },
        { text: "GRUPO COMISION", value: "Puesto" },
      ],
      // headersEmpleado: [
      //   { text: "TARJETA", value: "CodigoTarjeta" },
      //   { text: "NOMBRE", value: "Nombre" },
      //   { text: "GRUPO COMISION", value: "Puesto" },
      // ],
      // headersGestionado: [
      //   { text: "CODIGO SUCURSAL", value: "CodigoSucursal" },
      //   { text: "NOMBRE SUCURSAL", value: "NombreSucursal" },
      //   { text: "ASOCIADO", value: "CodigoAsociado" },
      //   { text: "CANAL", value: "Canal" },
      // ],
      // rowsPerPageItems: [50, 100, 200],
      // pagination: {
      //   rowsPerPage: 100,
      // },
      breadcrumb: [
        {
          text: "Configuración",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    sucursales() {
      if (this.currentUser.sucursales) {
        return this.currentUser.sucursales;
      } else {
        return [];
      }
    },
    isRolComisiones() {
      return this.currentUser.rol === "Comisiones";
    },
    canalOptions() {
      return this.$store.getters.canalesInternos;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    confirm() {
      this.user = this.currentUser;

      var _canales = [];
      var empleadosGestionados = [];

      let _corte = this.corteOptions.find(
        (c) => c.CorteId === this.selectedCorte
      );

      if (this.gestionados.length > 0) {
        var insumo = this.gestionados.filter(
          (c) => c.TipoCanal == this.user.tipoCanal
        );

        empleadosGestionados = insumo
          .filter((c) => c.Empleados != null)
          .map((c) => c.Empleados);

        _canales = insumo
          .filter((c) => c.CanalID != undefined)
          .map((c) => c.CanalID);
      }

      _canales.push(this.canalSelected);

      var canales = [];
      canales = _canales;
      this.user.corte = _corte;
      this.user.corteId = this.selectedCorte;
      this.user.canalid = this.canalSelected;
      this.user.seleccion = {
        empleados: [],
        gestionados: [],
        canales: [],
      };
      this.user.empleadoid = this.empleadoSelected;
      this.user.seleccion.empleados =
        this.empleados.concat(empleadosGestionados);
      this.user.seleccion.gestionados = this.gestionados;
      this.user.seleccion.canales = canales;
      this.user.perfil =
        this.empleados.length > 1 ? "Gerente" : "Representante";

      this.$store.dispatch("setUser", this.user);
      this.$store.dispatch("setCanal", "interno");
      this.$store.dispatch("getSegmento");
      let mensaje = {
        title: "Confirmación empleado",
        body: "Empleados seleccionados",
      };

      this.setMessage(mensaje);
      // }
      this.$router.push({ name: "home" });
    },
    getEmpleados() {
      apiEmpleado.getEmpleadosByCanal(this.canalSelected).then((response) => {
        this.empleadoOptions = response.data;
      });
    },
    getEmpleadosCadenaMando() {
      this.empleados = [];
      if (this.selectedCorte) {
        this.progress = true;
        apiEmpleado
          .getEmpleados(this.selectedCorte, this.empleadoSelected)
          .then((response) => {
            this.progress = false;
            this.empleados = response.data;
            var current = this.empleados.find(
              (c) => c.EmpleadosId === this.empleadoSelected
            );

            this.selectedEmpleado.push(current);
          });
      }
    },
    getCortesEmplado() {
      apiEmpleado.getCortes(this.empleadoSelected).then((response) => {
      this.corteOptions = response.data;
       response.data.forEach(item => {
            item.fechas =  item.Nombre.replace('WEE', ' - ') + ' (' + moment(item.StartDate).format('DD/MM/YYYY') + ' - ' + moment(item.EndDate).format('DD/MM/YYYY') + ')'
          })
      });
    },
    getGestionado() {
      this.progressGestionado = true;
      apiEmpleado.getGestionado(this.empleadoSelected).then((response) => {
        this.progressGestionado = false;
        this.gestionados = response.data;

        this.listaCanal = [...new Set(response.data.map((c) => c.Canal))];
      });
    },
    search() {
      if (this.group == "externo") {
        return;
      } else if (this.group == "interno") {
        this.getEmpleadosCadenaMando();
        this.getGestionado();
      }
    },
  },
  watch: {
    canalSelected: function () {
      this.getEmpleados();
    },
    // selectedCorte: function () {
    //   this.getEmpleadosCadenaMando();
    // },
    empleadoSelected: function () {
      this.getCortesEmplado();
      // this.getEmpleadosCadenaMando();
    },
  },
  mounted() {
    // // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
  },
};
</script>

